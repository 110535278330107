import React from "react"
import styled from "styled-components"
import img from '../../content/assets/topography.svg';

const Section = styled.div`
  background-image: url(${img});
`

const ThreeCol = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  margin: 0 auto;
  padding-bottom: 150px;
  grid-gap: 30px;

  @media (max-width: 768px) and (orientation: portrait) {
    width: 90%;
    grid-gap: 30px;
    padding-bottom: 60px;
  }

  @media (max-width: 530px) {
    display: grid;
    width: 90%;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

const Overline = styled.h2`
  color: #0D5C00;
  font-weight: 600;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  width: 80%;
  margin: 0 auto;
  padding: 120px 0 20px 0;

  @media (max-width: 768px) and (orientation: portrait) {
      width: 90%;
      padding: 50px 0 20px 0;
  }

  @media (max-width: 530px) {
    width: 90%;
    padding: 40px 0 20px 0;
  }
`

const Subhead = styled.h2`
    font-weight: 300;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.1;
    margin-bottom: 10px;
    width: 80%;
`

const Detail = styled.p`
    font-weight: 400;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3;
    margin-bottom: 10px;
`

const Anchor = styled.a`
  font-weight: 600;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding-top: 10px;
  line-height: 1;
  text-decoration: none;
  color: #0D5C00;
  &:hover {
    color: #00B227;
  }
`

function Benefits() {
    return (    
        <Section>
          <Overline>A flexible, online path for personal growth</Overline>
          <ThreeCol>
            <div>
              <Subhead>Design curriculum that’s available for everyone</Subhead>
              <Detail>You’ll always be able to access the curriculum produced by New Pragmatic for free. No paywalls. Ever.</Detail>
              <Anchor href="/courses">View Courses and Curriculum</Anchor>
            </div>
            <div>
              <Subhead>Dedicated one-on-one mentorship every week</Subhead>
              <Detail>Meet with your mentor for an hour each week to maintain progress and customize your path through the program.</Detail>
              <Anchor href="/mentorship">Learn more about mentorship</Anchor>
            </div>
            <div>
              <Subhead>Video feedback, delivered every weekday</Subhead>
              <Detail>Mentorship isn't limited to weekly meetings, as you'll receive written or video-based feedback daily to amplify your growth.</Detail>
              <Anchor target="_blank" href="https://www.youtube.com/playlist?list=PLWD7H2ECUEEs5rll3WX3yQn9AawSKl2_7">Watch a Recent Feedback Loop</Anchor>
            </div>
          </ThreeCol>
        </Section>
    )
}

export default Benefits