import React from "react"
import { Link } from 'gatsby'
import styled from "styled-components"

import './hero.css'
import './grow.css'

const GrowRight = styled.div`
  font-family: "Literata";
  font-size: 1.8rem;
  line-height: 1.8;
`

const GrowLeft = styled.div`
  margin-top: .8rem;
`

const Para = styled.p`
  margin-bottom: 2rem;
`
const Course = styled.div`
  margin-top: 2rem;
  line-height: 1.4;
  font-size: 1.6rem;
  color: 333;
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 1rem;
`

const List = styled.ul`
  margin: 1rem 0rem 2rem 0rem;
  font-size: 1.4rem;
`

const Anchor = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #0D5C00;
`

class GiftLink extends React.Component {
  componentDidMount() {
      this.stripe = Stripe('pk_live_W0xMBmT494GQkvMJ46wpCGK500aNqln1rZ');
  }
  render() {
      return(
          <form onSubmit={event => {
              event.preventDefault();
              this.stripe.redirectToCheckout({
                items: [{sku: 'sku_HDYIltKsSNCQJC', quantity: 1}],
                  // Do not rely on the redirect to the successUrl for fulfilling
                  // purchases, customers may not always reach the success_url after
                  // a successful payment.
                  // Instead use one of the strategies described in
                  // https://stripe.com/docs/payments/checkout/fulfillment
                  successUrl: window.location.protocol + '//newpragmatic.com/success',
                  cancelUrl: window.location.protocol + '//newpragmatic.com/mentorship',
                })
                .then(function (result) {
                  if (result.error) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer.
                    var displayError = document.getElementById('error-message');
                    displayError.textContent = result.error.message;
                  }
                });
              }
          }>
            <button type="submit">Give the Gift of Growth</button>
          </form>
      )
  }
}

class MentorButton extends React.Component {
  componentDidMount() {
      this.stripe = Stripe('pk_live_W0xMBmT494GQkvMJ46wpCGK500aNqln1rZ');
  }
  render() {
      return(
          <form onSubmit={event => {
              event.preventDefault();
              this.stripe.redirectToCheckout({
                lineItems: [{price: 'price_1IP1R7Jt5j48Yzc1XtNlwqoy', quantity: 1}],
                mode: 'subscription',
                  // Do not rely on the redirect to the successUrl for fulfilling
                  // purchases, customers may not always reach the success_url after
                  // a successful payment.
                  // Instead use one of the strategies described in
                  // https://stripe.com/docs/payments/checkout/fulfillment
                  successUrl: window.location.protocol + '//newpragmatic.com/success',
                  cancelUrl: window.location.protocol + '//newpragmatic.com/mentorship',
                })
                .then(function (result) {
                  if (result.error) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer.
                    var displayError = document.getElementById('error-message');
                    displayError.textContent = result.error.message;
                  }
                });
              }
          }>
            <button type="submit">Mentorship only $349 per month</button>
          </form>
      )
  }
}

class RetainButton extends React.Component {
  componentDidMount() {
      this.stripe = Stripe('pk_live_W0xMBmT494GQkvMJ46wpCGK500aNqln1rZ');
  }
  render() {
      return(
          <form onSubmit={event => {
              event.preventDefault();
              this.stripe.redirectToCheckout({
                  lineItems: [{price: 'price_1HdKeQJt5j48Yzc1yuU2jQQq', quantity: 1}],
                  mode: 'subscription',
                  // Do not rely on the redirect to the successUrl for fulfilling
                  // purchases, customers may not always reach the success_url after
                  // a successful payment.
                  // Instead use one of the strategies described in
                  // https://stripe.com/docs/payments/checkout/fulfillment
                  successUrl: window.location.protocol + '//newpragmatic.com/success',
                  cancelUrl: window.location.protocol + '//newpragmatic.com/mentorship',
                })
                .then(function (result) {
                  if (result.error) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer.
                    var displayError = document.getElementById('error-message');
                    displayError.textContent = result.error.message;
                  }
                });
              }
          }>
            <button className="small" type="submit">Private product consultation also available</button>
          </form>
      )
  }
}

function GrowHero() {
    return (  
      <div class="grow-wide">
        <div class="grow-back">
          <section class="hero-unit">
            <div>
              <div className="hero-logo">
                <Link to="/">
                  <img width="230" height="55" src={require('../../content/assets/initials-home.png')} />
                </Link>
              </div>
              <div class="hero-text">
                <h1>Let’s work together on your goals</h1>
                <h3>Few could have predicted the amount of change that the field of design has seen over the past decade. Whether you’re in the middle of your career or just beginning, you have the talent needed to succeed — all you need is a guide.</h3>
                <h5>That’s literally why I am here.</h5>
                <MentorButton />

                <a class="cal" target="_blank" href="https://calendly.com/np-chris/intro-mentoring-session">Not sure? Schedule an introductory call with Chris today!</a>

              </div>
            </div>
          </section>
        </div>
        <section class="growbody">
          <GrowLeft>
            <img width="100%" src={require('../../content/assets/meetup.jpg')} />
            <Course>
              <div>
                <Title>Recurring monthly mentorship</Title>
                <p>$349 simply goes further at New Pragmatic!</p>
                <List>
                  <li><strong>*</strong> Weekly, one-hour sessions with Chris Courtney.</li>
                  <li><strong>*</strong> Daily feedback on your work.</li>
                  <li><strong>*</strong> Guidance through <Anchor href="/courses">any New Pragmatic course material</Anchor> or individualized support for designers in Career Mode.</li>
                  <li><strong>*</strong> Access to daily video Feedback Loop review session.</li>
                  <li><strong>*</strong> Access to weekly workshop sessions.</li>
                  <li><strong>*</strong> Access to private New Pragmatic discussion channels.</li>
                </List>
              </div>
            </Course>
          </GrowLeft>
          <GrowRight>
            <Para>My name is Chris Courtney. I’ve been a designer and educator for over two decades.</Para>  
            
            <Para>I was about ten years into my career as a print designer when the iPhone arrived and changed everything. On the surface, designing for a new medium seemed straightforward enough, but the shift from paper to pixels would ultimately be the smallest part of the transition.</Para>

            <Para>My journey has taken me to places I didn’t initially consider to be design and deeper into the business concerns of organizations than I ever thought I would be interested.</Para>
            
            <Para>From initial research tasks to a realm far beyond the reach of coding, design touches everything. Design shapes society and policy. It’s inherently political as every decision is, regardless of our wishes otherwise.</Para>
            
            <Para>I’ve spent the past several years packaging my experience into lessons that helped students land jobs at Apple, Microsoft, Disney, LinkedIn, Mozilla, and many other excellent organizations.</Para>

            <Para>Having taught in traditional classrooms (Northwestern and Columbia) and led design programs at education startups (Bloc and Thinkful), <Anchor href="/testimonials">I understand the complex challenges that today’s design students face.</Anchor> I launched New Pragmatic borrowing the best from those experiences while eliminating every ounce of administrative waste possible.</Para>
            
            <Para>New Pragmatic is design education in its purest form. Designer-to-designer.</Para>

          </GrowRight>
        </section>
      </div>
    )
}

export default GrowHero