import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"

import SEO from "../components/seo"
import GrowHero from "../components/grow-hero"
import Benefits from "../components/benefits"
import Footer from "../components/footer"

class PragIndex extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    return (
      <div>
        <SEO title="Redesign Your Future" 
             keywords={[`design`, `education`, `mentorship`]} 
             image="https://newprag.s3.amazonaws.com/promo/OG_social2.jpg"
        />
        <GrowHero />
        <Footer />
      </div>
    )
  }
}

export default PragIndex
